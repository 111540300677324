import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '../../core/validators/custom.validator';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utils.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  registered_email = new FormControl('', [Validators.required, CustomValidators.email()])

  constructor(public utilityService: UtilityService, private userService: UserService) {}

  onClickLogin(){
    this.utilityService.onNavigate('/login')
  }

  sendResetLink() {
    this.userService.forgotPassword({
      "registered_email": this.registered_email.value || ''
    }).subscribe(res => {
      this.utilityService.onNavigate('/login')
    })
  }
}
