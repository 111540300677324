<div class="login-container claw-marks">
    <div class="row w-100 justify-content-center">
        <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
            <div class="pb-4 d-flex justify-content-center align-items-center">
                <img src="/assets/images/logo.png" alt="LeadStack Logo" loading="eager" width="300px" />
            </div>
            <form class="forgot-password-bg">
                <div class="row">
                    <div>
                        <h3 class="pb-2 font-20">Forgot your password</h3>
                        <p>Please enter the email address where you'd like the reset link to be sent.</p>
                        <div class="mb-3">
                            <label for="emailAddress">Enter Email Id</label>
                            <input type="text" id="emailAddress" class="w-100 form-control"
                                [formControl]="registered_email">
                            @if(registered_email.touched && registered_email.invalid){
                            <div class="error-text">
                                @if(registered_email.errors?.['required']){
                                <span>Email Address is required !</span>
                                }@else{
                                <span>please enter a valid email address !</span>
                                }
                            </div>

                            }
                        </div>

                        <div class="mb-3 text-center">
                            <button type="button" class="reset-request-btn text-white w-100 "
                                [disabled]="registered_email.invalid" (click)="sendResetLink()">Send reset link</button>
                        </div>
                        <div>
                            <button type="button" class="bg-transparent border-0 w-100 pointer underLineHover"
                                (click)="onClickLogin()">Back
                                to Login</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>